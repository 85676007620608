import { Container } from 'react-bootstrap';
import Banner from './Banner';
import One from './pieces/One';
import logo from '../../content/img/logo.png'
import { List } from 'reactstrap';




const Home = () => {
    return <Container>
        <>
            <header>
                <div className="container">
                    <h1>Loretta3</h1>
                    <p>Soluciones IT</p>
                </div>
            </header>

            <section className="cta">
                <div className="container">
                    <img src={logo} alt="Loretta3 Soluciones IT" class="logo" />


                    <h2>¡Soluciones informáticas a tu medida!</h2>
                    <p>Asesoramos profesionalmente a las empresas para la construcción ágil de software y apps para optimizar operaciones y procesos propios de cada negocio, con el fin de equipar a nuestros clientes para afrontar los retos de la era digital.
                        <List type="unstyled">
                            <li>
                                Asesoramiento profesional
                            </li>
                            <li>
                                Soluciones de software
                            </li>
                            <li>
                                Infraestructura digital a la medida
                            </li><li>
                                Automatización, monitoreo y control
                            </li><li>
                                Visualización y seguimiento
                            </li><li>
                                Reducción de costos y riesgos
                            </li><li>
                                Aumento de rentabilidad
                            </li><li>
                                Optimización de procesos
                            </li><li>
                                Competitividad IT.
                            </li>

                        </List>
                    </p>


                </div>
            </section>

            <section className="benefits">
                <div className="container">
                    <div className="benefit">
                        <h3>Consultoría</h3>
                        <p>Lo que se mide se puede controlar, se puede gestionar y se puede mejorar.  ¡Enfoca tus esfuerzos en lo que realmente importa!.</p>
                    </div>
                    <div className="benefit">
                        <h3>Presencia Digital</h3>
                        <p>En la era digital, si tu negocio no existe en internet tu negocio tiende a desaparecer.</p>
                    </div>
                    <div className="benefit">
                        <h3>Desarrollo a la medida</h3>
                        <p>Construcción de software personalizado de acuerdo con las necesidades de su compañía.</p>
                    </div>
                    <div className="benefit">
                        <h3>Infraestructura dígital</h3>
                        <p>Recursos y servicios para que su empresa opere eficientemente.</p>
                    </div>

                </div>
            </section>

            <section>
                <div className="container">
                    <h2>Contáctanos</h2>
                    <form action="submit_form.php" method="POST">
                        <label for="name">Nombre Completo:</label>
                        <input type="text" id="name" name="name" required />

                        <label for="email">Correo Electrónico:</label>
                        <input type="email" id="email" name="email" required />

                        <label for="company">Empresa (opcional):</label>
                        <input type="text" id="company" name="company" />

                        <label for="message">Mensaje:</label>
                        <textarea id="message" name="message" rows="4" required></textarea>

                        <button type="submit">Enviar</button>
                    </form>
                </div>
            </section>
            <footer>
                <div className="container">
                    <p>&copy; 2024 Loretta3 Soluciones IT. Todos los derechos reservados.</p>
                </div>
            </footer>
        </>
    </Container>
}

export default Home;