import { configureStore } from "@reduxjs/toolkit";
import siteSlice from "./features/slices/siteSlice";


const store = configureStore({
  reducer: {
    site: siteSlice,    
  }
})

export default store;

