import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './features/web/Home';
import { useSelector } from 'react-redux';
import { whatsAppBt } from './features/util/forms.util';

function App() {

  const {company} = useSelector((state) => state.site);
  return <>
  <BrowserRouter>
    <Routes>
      <Route path='*' element={<Home />} />
    </Routes>
  </BrowserRouter>
  {whatsAppBt(company)}
  </>
}

export default App;
